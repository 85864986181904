//https://html-color.codes/gold

import './App.css';
import Church from './Component/Church';
import Restaurant from './Component/Restaurant';
import ContactUs from './Component/ContactUs';
import Layout from './Layout/Layout';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Replay from './Component/Replay';
import AboutOur from './Component/AboutOur';
import Main from './Component/Main';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<Main />} />
            <Route path="/church" element={<Church />} />
            <Route path="/restaurant" element={<Restaurant />} />
            <Route path="/aboutOur" element={<AboutOur />} />
            <Route path="/replay" element={<Replay />} />
            <Route path="/connectUs" element={<ContactUs />} />
          </Route>
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
