
import Iframe from "react-iframe";

function Restaurant(){
    return(
        <div>
            <h2><center>Lakodalom</center></h2>
            <h3><center>Helyszín</center></h3>
            <p style={{"margin":"0 10%"}}>A lakodalom helyszíne a BME főépületének díszterme (K épület) <br />
            Cím: Budapest, 1111 Műegyetem rkp. 3.<br/>
            A helyszín lekönnyebben a Szent Gellért tér felől 7-es, 107-es vagy 133E buszokkal, 19, 41, 47, 48, 49, 56-os villamosokkal vagy 4-es metróval érhető el. A Műegyetem rakparton egész hétvégén ingyenes a parkolás, a főépület előtt nagy parkoló zóna található. Megközelíteni az épületet a Műegyetem rakparton levő főbejárat felől lehet, az épületen belül irányító táblák jelzik majd az utat. Távozni majd ugyanezen a bejáraton lehet. Az aulában elérhető lift.<br/>
            </p>
            <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2840.418673473582!2d19.05275021164154!3d47.48149631869656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dc536fe8b11f%3A0xbc1392d97a0fd14b!2sBudapesti%20M%C5%B1szaki%20%C3%A9s%20Gazdas%C3%A1gtudom%C3%A1nyi%20Egyetem!5e1!3m2!1shu!2shu!4v1717508961548!5m2!1shu!2shu" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></Iframe>
            <h3><center>Hasznos infók</center></h3>
            <h4><a href="https://docs.google.com/spreadsheets/d/1G_0ddEcvbFFozV6e7JmGw4JwEI1yYsYYCOV6Fw10afw/edit?usp=sharing" style={{color:"black", textDecoration:"none", padding:"5px", backgroundColor:"#daa520"}}>Nászajándék lista</a></h4>
            <p style={{fontWeight:"bold"}}>Dohányozni csak az épület főbejárata előtt lehetséges.</p>
            <p style={{"margin":"20px 10% 10px 10%"}}>A dekoráció fő színei a bordó, sötétzöld és fehér lesz.</p>
            <p style={{"margin":"10px 10%"}}>Dress code: báli hangulat, de a fő, hogy jól érezd magad, a nagyestélyit, szmokingot otthon hagyhatod.</p>
            <p style={{"margin":"10px 10% 20px 10%"}}>Klasszikus páros táncokkal és közösségi táncokkal is készülünk, de parti zene is lesz. Szeretnénk, ha mindenkinek lenne alkalma elkoptatni a cipőjét. A táncok közül az alábbi linkeken találtok videókat, amelyek segítenek az egyes táncokkal való ismerkedésben:
<ul style={{margin: "10px auto", width: "30%", alignContent: "center"}}>
    <li><a href="https://www.youtube.com/watch?v=TlYEdmbz3SI">bécsi keringő</a></li>
    <li><a href="https://www.youtube.com/watch?v=bf1qerP_Y6A">angol keringő</a></li>
    <li><a href="https://www.youtube.com/watch?v=O1G62CkipUc">cha cha</a></li>
    <li><a href="https://www.youtube.com/watch?v=XnZupc1aDEw">rumba</a></li>
    <li><a href="https://www.youtube.com/watch?v=N0YJ5Xjif3c">salsa</a> </li>
    <li><a href="https://www.youtube.com/watch?v=C5lUs4AV9zs">tangó</a> </li>
    <li><a href="https://youtu.be/e-ithpxbsRo?si=fAOGMEJr9qZDw74w">Zorba</a> </li> 
    <li><a href="https://www.youtube.com/watch?v=SlapLyUOdBY">polka</a> </li>
    <li><a href="https://www.youtube.com/watch?v=idSvyeWQMhg">galop</a> </li>
    <li><a href="https://www.youtube.com/watch?v=hSvnAotMuEs">Banumarocsini</a> </li>
    <li><a href="https://www.youtube.com/watch?v=2o8_FrYuJKU">serény magyaros</a> </li>
    <li><a href="https://www.youtube.com/watch?v=WgsAdzSO9lI">bulgáros</a> </li>
    <li><a href="https://www.youtube.com/watch?v=HQrRUCGMSJU">ördög útja</a> </li>
</ul></p>
            
            <div style={{width:"50%",margin:"0 auto"}}>
                <table>
                    <tr><td colSpan="2"><h3>Program</h3></td></tr>
                    <tr><td>17:00</td><td>vendégvárás</td></tr>
                    <tr><td>18:00</td><td>pár érkezése, köszöntők</td></tr>
                    <tr><td>18:30</td><td>vacsora</td></tr>
                    <tr><td>19:30</td><td>gratulációk fogadása, közben fényképezkedés</td></tr>
                    <tr><td>20:30</td><td>nyitótánc és klasszikus páros táncok, közösségi táncok</td></tr>
                    <tr><td>21:30</td><td>vetítés és játék</td></tr>
                    <tr><td>22:00</td><td>torta</td></tr>
                    <tr><td>22:30</td><td>közösségi táncok és néptánc</td></tr>
                    <tr><td>23:15</td><td>játék</td></tr>
                    <tr><td>23:30</td><td>gyertyafény keringő és menyasszonyrablás</td></tr>
                    <tr><td>00:00</td><td>buli</td></tr>
                    <tr><td>02:00</td><td>zárás</td></tr>
                </table>
            </div>
        </div>
    )
}

export default Restaurant;