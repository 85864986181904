import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';


import HeaderTitleLarge from './Menu/HeaderTitleLarge';
import HeaderTitleSmall from './Menu/HeaderTitleSmall';
import MenuAdmin from './Menu/MenuAdmin';
import MenuLarge from './Menu/MenuLarge';
import MenuSmall from './Menu/MenuSmall';


const theme = createTheme({
    status: {
        danger: '#e52e3e',
    },
    palette: {
        primary: {
            main: "#0971f1",
            darker: "#053e85",
        },
        neutral:{
            main: "#daa520",
            darker: "#fff",
        },
    },
});

function ResponsiveAppBar() {
    const pages = [
      {name:'Kezdőlap', link: "/"},
      {name:'Templomi szertartás', link: "/church"},
      {name:'Lakodalom', link: "/restaurant"}, 
      {name:'Rólunk', link: "/aboutOur"},
      {name:'Visszajelzés', link:"/replay"},
      {name:'Kapcsolat', link: "/connectUs"},
      {name:'Nászajándék', link: "https://docs.google.com/spreadsheets/d/1G_0ddEcvbFFozV6e7JmGw4JwEI1yYsYYCOV6Fw10afw/edit?usp=sharing"},
      {name:'Batyu', link:"https://docs.google.com/spreadsheets/d/1pO1B7js9ll1-qMf8719wGkelsSoQHQKDcqphHDTzN8c/edit?usp=sharing"},
    ];
    const access = false;
  
    return (
      <ThemeProvider theme={theme}>
      <AppBar position="static" color="neutral">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <HeaderTitleLarge />
            <MenuSmall pages = {pages}/>
            <HeaderTitleSmall />
            <MenuLarge pages = {pages}/>
            {access ? <MenuAdmin /> : null }
          </Toolbar>
        </Container>
      </AppBar>
      </ThemeProvider>
    );
  }
  export default ResponsiveAppBar;
