import szertartasrend from '../img/kep1.jpg';
import Card from 'react-bootstrap/Card';
import Iframe from 'react-iframe';

function Main(){
    return(
        <Card className="row">
                <div style={{width:"90%", padding:"0 auto"}}>
                    
                <h3>Kedves Barátaink!</h3>

                <center>Szeretettel meghívunk Benneteket, <br />
                <b>2024. szeptember 21-én 14 órára</b> <br />
                a <br />
                <b>budai ciszterci Szent Imre templomban</b><br />
                ahol Isten szent színe előtt<br />
                nászmise keretében összekötjük életünket, <br />
                valamint <br />
                <b>17 órától a BME "K" épület dísztermében tartandó lakodalomra.</b></center>


                <p style={{wordWrap:"break-word"}}>A nászmisés szertartás után mindenkit szeretettel várunk agapéra a templomkertben.</p>
                
                </div>
        </Card>
    )
}

export default Main;