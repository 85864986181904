//import FormfacadeEmbed from "@formfacade/embed-react";
import Iframe from 'react-iframe'
function Replay(){
    return(
        <div>
            <h2>VISSZAJELZÉS</h2>
            <p>Kérjük az alábbi űrlapot augusztus 20-áig töltsétek ki.<br />
            Előre is nagyon köszönjük.</p>
            <div style={{width: "60%", margin: "0px auto"}}>
            <Iframe url="https://docs.google.com/forms/d/e/1FAIpQLSfdJtYIyx9Ds3xeBfjcC4tIpQIwo8bD01YSqWWnQounp_gQXA/viewform"
        width="100%"
        height="1500px"
        id=""
        className=""
        display="block"
        position="relative"/>
        </div>

        </div>
    )
}

export default Replay;